import React from "react"
import SEO from "../components/global/Seo"
import Layout from "../components/layout/Layout"

export default function Kontakt() {
  return (
    <Layout pageTitle="404 - Strony nie znaleziono">
      <SEO title="Strony nie znaleziono" />
      <section className="section-padding">
        <h1 className="text-center">Strony nie znaleziono</h1>
      </section>
    </Layout>
  )
}
